const openEmailClient = (userEmail) => {
    console.log("opening inbox for ", userEmail)
    const email = 'services@carthy.ai';
    const subject = encodeURIComponent('Request for Task Execution');
    const body = encodeURIComponent(
        `Hello ${userEmail},\n\n` +
        `Please enter the task you would like us to perform for you, and feel free to include a file if necessary:\n` +
        `Please upload a file in one of the following formats: .doc, .docx, .pdf, .rtf, .epub, if it's relevant to your task.\n\n` +
        `Example:\n` +
        `Hi,\n\n` +
        `Please take a look at those 2 contracts and find the changes to the new one from the original one.\n\n` +
        `[Additional instructions or guidelines can be added here.]\n\n` +
        `Thank you,\n` +
        `[Your Company Name]`
    );

    const link = document.createElement('a');
    link.href = `mailto:${email}?subject=${subject}&body=${body}`;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

export default openEmailClient;
