//AllTasks.jsx
import React, {useState, useEffect} from 'react';
import '../css/AllTasks.css';
import {get} from 'aws-amplify/api';
import {fetchAuthSession} from "aws-amplify/auth";
import { FaPlus } from 'react-icons/fa'; // Import the icon component
import openEmailClient from '../functions/openEmailClient';




function timeAgo(date) {
  const now = new Date();
  const taskDate = new Date(date * 1000); // Assuming the date is in Unix timestamp format
  const diffInSeconds = Math.round((now - taskDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  } else {
    return taskDate.toLocaleDateString("en-US");
  }
}


const authToken = async () => {
    const session = await fetchAuthSession();
    console.log(session.tokens?.idToken?.toString())
    //return session.tokens?.idToken?.toString();
    const token = session.tokens?.idToken?.toString();
    const bearerToken = "Bearer " + token
    console.log("APP JS Token ", bearerToken)
    return bearerToken;
};

// Define some dummy data for the fallback
const dummyData = [
    // Existing dummy data entries
    {
        task: 'Profit and Loss report',
        respondTo: 'Hila@ey.com',
        createdOn: '5 minutes ago',
        attachedFiles: 5,
        allowedUsers: 10,
        status: 'Analyzing'
    },
    // Add more entries to make it 10
].concat(new Array(9).fill().map((_, i) => ({
    task: `Task ${i + 2}`,
    respondTo: `user${i + 2}@example.com`,
    createdOn: `${5 * (i + 1)} minutes ago`,
    attachedFiles: i % 5,
    allowedUsers: 10 - i,
    status: i % 2 === 0 ? 'Analyzing' : 'Ready',
})));

const TaskRequestsTable = ({userEmail}) => {
    const [taskRequests, setTaskRequests] = useState(dummyData); // Set dummy data initially
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Items per page set to 5
     const handleNewRequestClick = () => {
    // Perform the same action as clicking the active card
    openEmailClient(userEmail);
  };


    console.log("Taskrequest", userEmail)
    useEffect(() => {

        const fetchData = async () => {
            console.log("user email :", userEmail)
            try {
                const bearer = await authToken();
                // Fetch logic goes here
                console.log("fetching")
                const restOperation = get({
                    apiName: 'tasks',
                    path: '/',
                    options: {
                        headers: {
                            Authorization: bearer
                        }
                    }
                });

                const {body} = await restOperation.response;
                const str = await body.json();
                const tasks = str.sort((a, b) => b.TaskCreatedAt - a.TaskCreatedAt);

                console.log(tasks)
                setTaskRequests(str)
            } catch (error) {
                console.error('Fetching data failed, using dummy data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
         const intervalId = setInterval(() => {
        fetchData();
    }, 60000); // 60 seconds

    // Clean up the timer when the component unmounts
    return () => {
        clearInterval(intervalId);
    };
    }, [userEmail]);

    const totalPages = Math.ceil(taskRequests.length / itemsPerPage);
    // Calculate the tasks to be displayed on the current page
    const indexOfLastTask = currentPage * itemsPerPage;
    const indexOfFirstTask = indexOfLastTask - itemsPerPage;
    const currentTasks = taskRequests.slice(indexOfFirstTask, indexOfLastTask);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }


    if (isLoading){
        return ( // Display loading animation if isLoading is true
            <div className="loading-container loading-overlay">
              <div className="loading-spinner"></div>
            </div>)
    }

    return (
        <div className="task-requests-table-container">
            <div className="header-container">
                <h2 className="header">All Task Requests</h2>
                <button className="new-request-button" onClick={ handleNewRequestClick }> <FaPlus/> Task Request {/* Include the icon component */}</button>

            </div>
            <table className="task-requests-table">
                <thead>
                <tr>
                    <th>Requested Task
                        <span className="sort-icon">▲</span>
                    </th>
                    <th>Respond to
                        <span className="sort-icon">▲</span>
                    </th>
                    <th>Created
                        <span className="sort-icon">▲</span>
                    </th>
                    <th>Attached files
                        <span className="sort-icon">▲</span>
                    </th>
                    <th>Allowed users
                        <span className="sort-icon">▲</span>
                    </th>
                    <th>Status
                        <span className="sort-icon">▲</span>
                    </th>
                </tr>
                </thead>

                <tbody>
                { currentTasks.map((task, index) => ( // Use currentTasks here instead of taskRequests
                    <tr key={ index }>
                        <td>{ task.TaskName }</td>
                        <td>{ task.UserId }</td>
                        <td>{ timeAgo(task.TaskCreatedAt) }</td>
                        <td>{ task.TotalSteps }</td>
                        <td>{ task.Progress }</td>
                        <td>
                            <div
                                className={ `status-dot ${ task.Status === 'Email Delivered' ? 'green' : task.Status === 'Queued' ? 'queued' : 'flashing' }` }></div>
                            { task.Status }</td>
                    </tr>)) }
                </tbody>


            </table>
            <div className="pagination">
                { Array.from({length: totalPages}, (_, i) => i + 1).map(number => (
                    <button key={ number } onClick={ () => paginate(number) }>
                        { number }
                    </button>
                )) }
            </div>
        </div>
    );
};

export default TaskRequestsTable;
