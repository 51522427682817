//App.js
import React, {useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';

import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import {withAuthenticator} from '@aws-amplify/ui-react';
import './App.css';
import Header from './ui-components/Header';
import SideMenu from './ui-components/SideMenu';
import Dashboard from './ui-components/Dashboard';
import openEmailClient from './functions/openEmailClient';
import TaskRequestsTable from './ui-components/AllTasks';
import {fetchUserAttributes} from "aws-amplify/auth";
import PrivacySecurity from "./ui-components/PrivacySecurity";
import AuditLog from "./ui-components/AuditLog";
import Integrations from "./ui-components/Integrations";
import Assistants from "./ui-components/Assistants";
import RequestsArchive from "./ui-components/RequestsArchive";
import UserManagement from "./ui-components/UserManagement";

//import { fetchAuthSession } from 'aws-amplify/auth'
//const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();


Amplify.configure(config);
const existingConfig = Amplify.getConfig();
Amplify.configure({
    ...existingConfig,
    API: {
        ...existingConfig.API,
        REST: {
            ...existingConfig.API?.REST,
            tasks: {
                endpoint: 'https://0vgkibpk0e.execute-api.us-east-1.amazonaws.com/prod',
            },
            auditlog: { // Adding a new API configuration
                endpoint: 'https://6zsn14hle0.execute-api.us-east-1.amazonaws.com/prod',
            }
        }
    }
});

const TaskRequests = () => <div>All Task Requests</div>;

function App({signOut, user}) {
    const toggleMenu = () => {
        console.log('Toggling menu'); // Should log when you click the menu icon
        setIsMenuOpen(!isMenuOpen);
    };
    const [userEmail, setUserEmail] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
      const [isLoading, setIsLoading] = useState(true); // Added isLoading state

    console.log("user email ", userEmail)


    const getUserAttributes = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            // Assuming 'email' is the key containing the user's email
            const emailAttribute = userAttributes.email;
            if (emailAttribute) {
                setUserEmail(emailAttribute);
                setIsLoading(false);

                //console.log("User email attribute", emailAttribute)
            }
        } catch (error) {
            console.error('Error fetching user attributes:', error);
        }
    };
    getUserAttributes()
     return (
    <Router>
      <div className={`app-container ${isMenuOpen ? 'menu-open' : ''}`}>
        <Header toggleMenu={toggleMenu} signOut={signOut} />

        <SideMenu isMenuOpen={isMenuOpen} signOut={signOut} />

        <main className="app-content">
          {isLoading ? ( // Display loading animation if isLoading is true
            <div className="loading-container loading-overlay">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Dashboard openEmailClient={openEmailClient} userEmail={userEmail} />} />
              <Route path="/task-requests" element={<TaskRequestsTable userEmail={userEmail} />} />
              <Route path="/privacy-security" element={<PrivacySecurity userEmail={userEmail} />} />
              <Route path="/audit-log" element={<AuditLog userEmail={userEmail} />} />
              <Route path="/integrations" element={<Integrations userEmail={userEmail} />} />
              <Route path="/assistants" element={<Assistants userEmail={userEmail} />} />
                <Route path="/requests-archive" element={<RequestsArchive userEmail={userEmail} />} />
                <Route path="/user-management" element={<UserManagement userEmail={userEmail} />} />



              {/* ... more routes for other pages */}
            </Routes>
          )}
        </main>
      </div>
    </Router>
  );
}

export default withAuthenticator(App);
