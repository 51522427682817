import React, { useEffect, useState } from 'react';
import { MdChat, MdEmail } from "react-icons/md";
import { fetchUserAttributes } from "aws-amplify/auth";
import openEmailClient from '../functions/openEmailClient';


const Dashboard = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        // Assuming 'email' is the key containing the user's email
        const emailAttribute = userAttributes.email;
        if (emailAttribute) {
          console.log("Setting User email attribute", emailAttribute)
          setUserEmail(emailAttribute);


        }
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    getUserAttributes();
  }, []);

  const handleEmailClick = () => {
    openEmailClient(userEmail);
  };

  return (
      <div>
        <p className='main-heading'>What would you like to do first?</p>
        <div className="cards-container">
          <div className="card active-card" onClick={handleEmailClick}>
            <div className="card-top">
              <MdEmail className="card-icon"/>
            </div>
            <div className="card-bottom">
              <h2>Requests with Email</h2>
              <p>Task requests, analyze reports, and document insights generated by the tools you use on a daily
                basis</p>
            </div>
          </div>
          <div className="card disabled-card">
            <div className="card-top">
              <MdChat className="card-icon"/>
            </div>
            <div className="card-bottom">
              <h2>TBD Assistant</h2>
              <p>Task requests, analyze reports, and document insights within Carthy.ai</p>
            </div>
            <div className="tooltip">Coming Soon</div>
          </div>
        </div>
      </div>
  );
};

export default Dashboard;
