import React, { useState, useEffect } from 'react';
import '../css/AllTasks.css';
import '../css/AuditLog.css';
import { get } from 'aws-amplify/api';
import {fetchAuthSession} from "aws-amplify/auth";



function timeAgo(date) {
  const now = new Date();
  const taskDate = new Date(date * 1000); // Assuming the date is in Unix timestamp format
  const diffInSeconds = Math.round((now - taskDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  } else {
    return taskDate.toLocaleDateString("en-US");
  }
}


const authToken = async () => {
    const session = await fetchAuthSession();
    console.log(session.tokens?.idToken?.toString())
    //return session.tokens?.idToken?.toString();
    const token = session.tokens?.idToken?.toString();
    const bearerToken = "Bearer " + token
    console.log("APP JS Token ", bearerToken)
    return bearerToken;
};

// Define some dummy data for the fallback
const dummyData = [
    // Existing dummy data entries
    {
        task: 'Profit and Loss report',
        respondTo: 'Hila@ey.com',
        createdOn: '5 minutes ago',
        attachedFiles: 5,
        allowedUsers: 10,
        status: 'Analyzing'
    },
    // Add more entries to make it 10
].concat(new Array(9).fill().map((_, i) => ({
    task: `Task ${i + 2}`,
    respondTo: `user${i + 2}@example.com`,
    createdOn: `${5 * (i + 1)} minutes ago`,
    attachedFiles: i % 5,
    allowedUsers: 10 - i,
    status: i % 2 === 0 ? 'Analyzing' : 'Ready',
})));

const AuditLog = ({ userEmail }) => {
    const [taskRequests, setTaskRequests] = useState(dummyData);
    const [showModal, setShowModal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [auditLogs, setAuditLogs] = useState([]); // State to store audit logs
    const [selectedTaskId, setSelectedTaskId] = useState(null); // State to track selected Task ID
const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Items per page set to 5
    // Existing useEffect and other logic...
    useEffect(() => {

        const fetchData = async () => {
            console.log("user email :", userEmail)
            try {
                const bearer = await authToken();
                // Fetch logic goes here
                console.log("fetching")
                const restOperation = get({
                    apiName: 'tasks',
                    path: '/',
                    options: {
                        headers: {
                            Authorization: bearer
                        }
                    }
                });

                const {body} = await restOperation.response;
                const str = await body.json();
                const tasks = str.sort((a, b) => b.TaskCreatedAt - a.TaskCreatedAt);

                console.log(tasks)
                setTaskRequests(str)
            } catch (error) {
                console.error('Fetching data failed, using dummy data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
         const intervalId = setInterval(() => {
        //fetchData();
    }, 60000); // 60 seconds

    // Clean up the timer when the component unmounts
    return () => {
        clearInterval(intervalId);
    };
    }, [userEmail]);
const totalPages = Math.ceil(taskRequests.length / itemsPerPage);
    // Calculate the tasks to be displayed on the current page
    const indexOfLastTask = currentPage * itemsPerPage;
    const indexOfFirstTask = indexOfLastTask - itemsPerPage;
    const currentTasks = taskRequests.slice(indexOfFirstTask, indexOfLastTask);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const fetchAuditLogs = async (taskId) => {
        console.log("TASK ID",taskId)
        const bearer = await authToken();
        try {
            const response = await get({
                apiName: 'auditlog', // Use the correct API name for the auditlog API
                path: '/?taskid='+ taskId, // Adjust the path according to your API's design
                //path:'/',
                options: {
                    headers: {
                        Authorization: bearer
                    }
                }
            });
             const {body} = await response.response;
             const str = await body.json();
            console.log("Response from audit log ", str)
            setAuditLogs(str); // Assuming the response directly contains the audit log data
        } catch (error) {
            console.error('Fetching audit logs failed', error);
            setAuditLogs([]);
        }
    };

    const handleRowClick = (taskId) => {
        setSelectedTaskId(taskId);
        fetchAuditLogs(taskId);
        setShowModal(true);
    };
    const handleCloseModal = () => {
    setShowModal(false);
};

    // Existing rendering logic...
    if (isLoading){
        return ( // Display loading animation if isLoading is true
            <div className="loading-container loading-overlay">
              <div className="loading-spinner"></div>
            </div>)
    }
return (
    <div className="task-requests-table-container">
        <div className="header-container">
            <h2 className="header">Select a Request</h2>
            {/* Include any additional buttons or content here */}
        </div>
        <table className="task-requests-table">
            <thead>
                <tr>
                    <th>Requested Task</th>
                    <th>Respond to</th>
                    <th>Created</th>
                    <th>Attached files</th>
                    <th>Allowed users</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {currentTasks.map((task, index) => (
                    <tr key={index} onClick={() => handleRowClick(task.TaskId)}>
                        <td>{task.TaskName}</td>
                        <td>{task.UserId}</td>
                        <td>{timeAgo(task.TaskCreatedAt)}</td>
                        <td>{task.TotalSteps}</td>
                        <td>{task.Progress}</td>
                        <td>
                            <div className={`status-dot ${task.Status === 'Email Delivered' ? 'green' : task.Status === 'Queued' ? 'queued' : 'flashing'}`}></div>
                            {task.Status}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                <button key={number} onClick={() => paginate(number)}>
                    {number}
                </button>
            ))}
        </div>
        {/* Audit Logs Section */}
        {selectedTaskId && (
            <div className={`modal ${showModal ? 'show' : ''}`} onClick={handleCloseModal}>
                <div className="modal-content" onClick={ e => e.stopPropagation() }>
                    <span className="close" onClick={ handleCloseModal }>&times;</span>
                    <h3>Audit Logs for Task ID: { selectedTaskId }</h3>
                    {/* ...rest of your audit log table... */ }
                    <table className="audit-logs-table">
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Action</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        { auditLogs.map((log, index) => (
                            <tr key={ index }>
                                <td>{ timeAgo(log.UpdateTimestamp) }</td>
                                <td>From "{ log.PreviousStatus }" to "{ log.CurrentStatus }"</td>
                                <td>Task ID: { log.TaskId }</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </div>

        ) }
    </div>
);


};

export default AuditLog;
