// PrivacySecurity.jsx
import React, { useState } from 'react';
import '../css/PrivacySecurity.css'; // Assuming you have CSS similar to your AllTasks.css

const PrivacySecurity = () => {
  // States for each privacy setting
  const [visibility, setVisibility] = useState({
    myCompany: false,
    onlyMe: true,
    selectUsers: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Handlers for toggle changes
  const toggleVisibility = (setting) => {
    setVisibility((prev) => ({ ...prev, [setting]: !prev[setting] }));
  };

  return (
    <div className="privacy-security-container">
      <h2>Privacy & Security</h2>
      <div className="visibility-settings">
        <label className="toggle-container">
          My Company
          <input
            type="checkbox"
            checked={visibility.myCompany}
            onChange={() => toggleVisibility('myCompany')}
          />
          <span className="toggle-slider"></span>
        </label>

        <label className="toggle-container">
          Only Me
          <input
            type="checkbox"
            checked={visibility.onlyMe}
            onChange={() => toggleVisibility('onlyMe')}
          />
          <span className="toggle-slider"></span>
        </label>

        <label className="toggle-container">
          Select Users
          <input
            type="checkbox"
            checked={visibility.selectUsers}
            onChange={() => toggleVisibility('selectUsers')}
          />
          <span className="toggle-slider"></span>
        </label>
      </div>
    </div>
  );
};

export default PrivacySecurity;
