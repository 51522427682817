// UserManagement.jsx
import React, { useState } from 'react';
//import '../css/UserManagement.css'; // Assuming you have CSS similar to your AllTasks.css
import '../css/comingSoon.css'

const UserManagement = () => {
  // States for each privacy setting
  const [visibility, setVisibility] = useState({
    myCompany: false,
    onlyMe: true,
    selectUsers: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Handlers for toggle changes
  const toggleVisibility = (setting) => {
    setVisibility((prev) => ({ ...prev, [setting]: !prev[setting] }));
  };

  return (
    <div className="privacy-security-container">
      <h2>User Management</h2>
        <div className="parent">
            <div className="child"><h3>Coming Soon!</h3></div>
        </div>

    </div>
  );
};

export default UserManagement;
