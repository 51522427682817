// SideMenu.js
import React from 'react';
import {Link} from 'react-router-dom';

import { FaTachometerAlt, FaClipboardList, FaPuzzlePiece, FaUserFriends, FaArchive, FaUsersCog, FaHistory } from 'react-icons/fa';


import '../css/SideMenu.css';
import {MdMenu, MdSecurity} from "react-icons/md";


const SideMenu = ({isMenuOpen, toggleMenu, signOut}) => {
            const menuClass = isMenuOpen ? 'side-menu open' : 'side-menu';


    return (
        <nav className="side-menu">

            <li>
                <button>
                    <FaTachometerAlt className="icon"/>
                    <Link to="/" onClick={ toggleMenu }>Dashboard</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaClipboardList className="icon"/>
                    <Link to="/task-requests" onClick={ toggleMenu }>Task Requests</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaPuzzlePiece className="icon"/>
                    <Link to="/integrations" onClick={ toggleMenu }>Integrations</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaUserFriends className="icon"/>
                    <Link to="/assistants" onClick={ toggleMenu }>Assistants</Link>
                </button>
            </li>
            <li>
                <button>
                    <MdSecurity className="icon"/>
                    <Link to="/privacy-security" onClick={ toggleMenu }>Privacy & Security</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaArchive className="icon"/>
                    <Link to="/requests-archive" onClick={ toggleMenu }>Requests Archive</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaUsersCog className="icon"/>
                    <Link to="/user-management" onClick={ toggleMenu }>User Management</Link>
                </button>
            </li>
            <li>
                <button>
                    <FaHistory className="icon"/>
                    <Link to="/audit-log" onClick={ toggleMenu }>Audit Log</Link>
                </button>
            </li>
            {/* ... more menu items */ }
        </nav>

    );
};

export default SideMenu;
